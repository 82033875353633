.AccUpdateButton {
  margin-top: 30px !important;
  margin: 0 auto;
}

/* Adjust the position of the label */
.confirm-password-label {
  position: relative;
  top: -28px; /* Adjust this value as needed */
}

/* Adjust the position of the input field */
.confirm-password-input {
  margin-top: 10px; /* Adjust this value as needed */
}

/* Adjust the position of the eye icon button */
.confirm-password-icon {
  position: relative;
  top: -34px; /* Adjust this value as needed */
}
