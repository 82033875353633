.assets_recent_emails {
  color: var(--font-color);
  margin-top: 20px;
  margin-bottom: -25px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(196, 189, 189);
}

.assetsarchive_recent {
  margin-top: 20px;
}

.asset-archive-option {
  display: flex;

  justify-content: space-between;
}

.assetsarchive_grid {
  margin-top: 20px;
}

/* .assetsrecentimage .continue-img {
} */

.asset-archive-email-recent {
  background-color: var(--bg-block-color);
  /* border-radius: 22px; */
  padding: 0px;
  box-shadow: var(--box-shadow);
}

/* .asset-archive-email-recent .continue-img{
  width: 100% !important;
  border-radius: 22px 22px 0px 0px;
} */
.assetsrecentimage {
  padding: 0px !important;
}

.emails_paggination {
  margin-top: 30px;
  display: grid;
  place-items: center;
}

.avatar_name {
  text-align: right;
}

.dash-icon-dynamic {
  width: var(--width);
}

.image-wrap-assets-recent {
  display: inline-block;
  width: 100%;
  height: 250px;
  overflow: hidden;
  cursor: pointer;
}

.image-wrap-assets-recent img {
  width: 100%;
  height: 100%;
  border-radius: 22px 22px 0px 0px;
  object-fit: cover;
  object-position: top center;
  transition: all 3s ease-in-out;
}

.image-wrap-assets-recent:hover img {
  object-position: bottom center;
}

.side-folder {
  overflow-y: auto;
  padding: 10px;
  height: 65vh;
  background: var(--bg-block-color);
  /* border-radius: 20px; */
  position: sticky;
  top: var(--space);
  /* box-shadow: var(--box-shadow); */
}

.content-nav-asset {
  padding: 10px;
}

.active-asset {
  color: var(--active-font-color);
  background: var(--active-bg-color);
  /* border-radius: 12px; */
}

.DataDetails {
  display: flex;
  align-items: baseline;
  margin-bottom: 2%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust this value as needed */
  display: inline-block;
  vertical-align: top;
}

/* .DataDetails:hover {
  white-space: normal;
  overflow: visible;
  text-overflow: inherit;
  max-width: none;
} */
