.errorpage .btnerror {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: bold;
  margin: 5px 0px;
}

.errorpage .btnerror.green {
  border: 4px solid #2ccf6d;
  color: #0e0620;
}

.errorpage .btnerror.green:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background: #2ccf6d;
  z-index: -1;
  transition: 0.2s ease;
}

.errorpage .btnerror.green:hover {
  color: #fff;
  background: #2ccf6d;
  transition: 0.2s ease;
}

.errorpage .btnerror.green:hover:before {
  width: 100%;
}
