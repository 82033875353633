.create-new-project {
  display: flex;
  justify-content: space-evenly;
}
.dataset {
  background-color: var(--bg-block-color);
}

.center-div-img-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  flex-direction: column;
}

.center-div-img-text img {
  width: 33%;
}
picture {
  text-align: center;
}

.create-new-project-type {
  font-size: 15px;
  margin-top: 10px;
  font-weight: bold;
  color: var(--font-color);
  font-family: "Poppins", sans-serif;
}

/* .container-flex {
  display: grid;
  grid-gap: 0px;

  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, auto);
} */

.container-parent {
  display: flex;
  justify-content: center; /* Center the child horizontally */
  align-items: center; /* Center the child vertically */
  /* height: 100vh; Set height to 100% of the viewport */
}

.container-flex {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(
    3,
    minmax(0, 300px)
  ); /* Adjust the minmax values as needed */

  max-width: 800px; /* Set a maximum width for the grid */
  margin: 0 auto; /* Center the grid horizontally */
}

.container-flex-onebox {
  display: grid;
  /* grid-gap: 10px; */
  grid-template-columns: repeat(
    1,
    minmax(0, 300px)
  ); /* Adjust the minmax values as needed */

  max-width: 800px; /* Set a maximum width for the grid */
  margin: 0 auto; /* Center the grid horizontally */
}

.create-new-project .new-campaign-detail {
  width: 100%;
}
