/* .welcomeMessage
{
    padding: 0px 50px 0px 50px;
    background: #fff;
} */
/* .headernavafterlogin {
    padding-bottom : 20px !important;
} */
.Sort {
  margin-bottom: 15px;
}

.navicon {
  border: none;
}

.rs-nav-item-active {
  border-bottom: none !important;
}

.rs-nav-horizontal {
  border-bottom: none !important;
}

.selectEmail li {
  cursor: pointer !important;
  margin-top: 0px !important;
  margin: 5px;
  padding: 5px;
}

/* .selectEmail li:hover {
    background: #e7e7e7;
   transform: scale(1.1);
   
    } */

.isActive {
  color: white;
  background-color: #666;
}

.dataisActive {
  color: white;
  background-color: #666;
}

.error {
  position: absolute;
  padding: 4px 8px;
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  z-index: 5;
  color: #f44336;
  font-size: 12px;
  white-space: nowrap;
  /* display: none; */
}

/* Start Image template CSS */

input[type="radio"][id^="emailList"] {
  display: none !important;
}

input[type="radio"][id^="dataList"] {
  display: none !important;
}

.TemplateList2 label {
  border: 1px solid #fff;
  text-align: center;
  padding: 10px;
  display: block;
  position: relative;
  /* margin: 10px; */
  cursor: pointer;
}

.TemplateList2 label:before {
  background-color: white;
  color: white;
  content: " ";

  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: 10px;
  left: 140px;
  width: 30px;
  /* height: 30px; */
  text-align: center;
  /* line-height: 28px; */
  transition-duration: 0.4s;
  transform: scale(0);
  z-index: 1;
  padding: 10px;
}

.TemplateList2 label img {
  width: 200px;
  transition-duration: 0.2s;
  /* transform-origin: 50% 50%; */
  z-index: 0;
}

:checked + label {
  border-color: #ddd;
}

:checked + label:before {
  content: "\2713";
  background-color: grey;
  transform: scale(1);
}

:checked + label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}

.ScheduleModel .rs-modal-content {
  width: 360px !important;
}

.emailDescription td {
  padding: 7px;
  font-size: 13px;
}

.background-color-table {
  background-color: var(--bg-block-color);
  width: 90%;
  padding: 30px;
  /* border-radius: 30px; */
}
#throttle {
  color: #000;
}


.camdefDetails {
 cursor: pointer;
  margin-bottom: 2%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px; /* Adjust this value as needed */
  display: inline-block;
  vertical-align: top;
}