.content-container {
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 1rem;
}

.navbar-home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hero-head {
  font-weight: bold;
  color: var(--font-color);
  font-family: "Poppins", sans-serif;
}

.profile {
  width: 44px;
}

.email-details {
  /* background-color: var(--bg-block-color); */
  border-radius: 8px;
  text-align: center;
  padding: 15px 0px;
  margin-top: 12px;
  /* margin-right: 2%; */
  /* box-shadow: var(--box-shadow); */
}

.email-detail-sent {
  font-weight: 600;
  margin-bottom: 14px !important;
  padding-left: 4px;
  padding-right: 4px;
  color: #fff;
  /* border: 1px solid rgb(97, 96, 96); */
  background-color: #415670;
}
.rs-panel-body,
.rs-panel-header {
  padding: 0px !important;
}

/* .email-detail-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
} */

.border-right {
  border-right: 1px solid var(--font-color);
}

.emails-icon {
  height: 40px;
  width: 40px;
  margin-right: 15px;
}

.Tnumber {
  font-size: 31px;
}
.overall_heading {
  letter-spacing: 1px;
}

.new-campaign-row {
  margin-top: 50px;
}

.new-campaign-detail {
  background-color: var(--bg-block-color);
  /* border-radius: 18px; */
  padding: 18px;
  /* box-shadow: var(--box-shadow); */
}

.Campaign-icon {
  height: 80px;
  width: 80px;
  margin-right: 15px;
}

.NewC-number {
  font-size: 18px;
  color: var(--font-color);
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.RecentE-number {
  font-size: 18px;
  color: var(--font-color);
  font-family: "Poppins", sans-serif;
  text-align: left;
  padding-left: 15px;
}

.rs-radio-wrapper .rs-radio-inner:before,
.rs-radio-wrapper:before {
  height: 28px !important;
  width: 28px !important;
  background: #fff !important;
}

.rs-radio label {
  font-size: 17px;
  color: var(--font-color);
}

.radio-button-home input[type="radio"]:checked ~ .rs-radio-inner {
  border: 5px solid #0dff92 !important;
  z-index: 5;
}

.radio-button-home input[type="radio"]:checked ~ .rs-radio-inner::before {
  background: #0dff92 !important;
  z-index: 5;
}

.radio-button-home input[type="radio"]:checked > .rs-radio-checker label {
  background-color: #0dff92;
}

.latest-campaigns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
}

.rs-panel-bordered {
  padding: 5px !important;
}
.rs-panel-title {
  padding: 0px !important;
}

.recent-emails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  padding: 17px;
}

.rs-radio-checker {
  padding-top: 14px;
}

.campaign-image {
  width: 60px;
  height: 35px;
  border-radius: 22px;
}

.latest-campaign-text {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 600;
  word-wrap: break-word;
}

.recent-email-text {
  color: var(--font-color);
}

.showDataMon p {
  font-size: 12px;
}

.displayInline {
  display: inline-block;
}

.displayInline span {
  font-weight: bold;
}

.StatusA {
  border: 1px solid #fff;
  background-color: green;
  color: #fff;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 5px;
  float: right;
}

.latest-campaign {
  height: 150px;
  overflow-y: scroll; /* or overflow-y: auto; */
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
}

.latest-campaign-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}
