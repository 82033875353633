.form-control:focus {
  -webkit-box-shadow: none;
  outline: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.forError {
  position: absolute;
  padding: 4px 8px;
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  /* filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1)); */
  z-index: 5;
  color: #f44336;
  font-size: 12px;
  white-space: nowrap;
}

.tableUser td {
  padding: 10px;
}

.table-userListRoleIdbordered {
  color: var(--font-color);
}

.border-table-management {
  border: 1px solid var(--font-color);
}

.NewPassword{
  margin: 0px;
  height: 40px;
}

.NewPassword .rs-form-group {
  width: 100%;
}
