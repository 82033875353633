@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";

:root {
  --bg-block-color: #f0f0f0;
  --font-color: #000;
  --width: 17%;
  --space: 2rem;
  --space-xs: calc(var(--space) / 3);
  --space-sm: calc(var(--space) / 2);
  --space-md: calc(var(--space) * 2);
  --box-shadow: 5px 5px 5px #d7d7d7;
  --active-bg-color: #e5e5ea;
  --active-font-color: #000;
}

[data-theme="dark"] {
  --bg-block-color: #001c39;
  --font-color: #fff;
  --width: 13%;
  --active-bg-color: rgb(132, 145, 162);
  --active-font-color: #000;
}

.app-main-padding {
  padding: 0px 20px 0px 0px;
}

.before-sigin-bg-img {
  background: url("./components/Auth/assets/icons/background.png");
  background-size: cover;
  background-color: #e0e9ed;
}

.content-home {
  /* border:1px solid teal; */
  /* margin: 0px 6px 30px 15px; */
  margin: 0 0% 0% 1%;
}

.footer-pro-tip {
  display: flex;
  gap: 40px;
}

.pro-tip {
  display: flex;
  flex-direction: column;
}

.pro-tip-hr {
  border-bottom: 1px solid #7d7d7d;
}

.pointer-for {
  cursor: pointer;
}

.footer-pro-tip img {
  width: 100px;
}

/* 
.folder-structure-sync {
  margin-left: -10px;
}

.folder-structure-sync-assets {
  margin-left: -30px;
} */

.folder-structure-sync-assets .e-list-text {
  color: var(--font-color);
}

.e-treeview .e-list-item div.e-icons {
  color: var(--font-color);
}

.custom-error-fields {
  /* background-color: #fff;
  background-color: var(--rs-form-errormessage-bg); */
  /* border: 1px solid #e5e5ea; */
  /* border: 1px solid var(--rs-form-errormessage-border); */
  border-radius: 6px;
  color: #f44336;
  -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  font-size: 12px;
  line-height: 20px;
  /* padding: 3px 7px; */
  position: absolute;
  white-space: nowrap;
  z-index: 5;
}
.device-canvas {
  height: 281px;
}
.device-canvas canvas {
  width: 100% !important;
}
/* .folder-structure-sync-assets .e-list-item.e-active .e-list-text {
  color: #e3165b !important;
} */

.e-treeview .e-list-icon {
  background-repeat: round;
  background-image: url(https://charlie-dave.s3.amazonaws.com/folder-001.png);
  height: 20px; /* Set the desired height */
  width: 20px; /* Set the desired width */
}

/* .e-treeview .e-list-icon.folder {
  background-position: -10px -552px;
} */

.hide-sidebar-header {
  display: none;
}

.link-color-font {
  color: var(--font-color) !important;
}

.button-background {
  background: none !important;
  color: var(--font-color) !important;
  border-radius: 0px !important;
  border-bottom: 2px solid var(--font-color) !important;
  padding: 2px 5px !important;
}

.icon-color {
  color: var(--font-color);
}
.rs-input,
.rs-picker-toggle,
.rs-panel {
  border-radius: 0px !important;
}
/* BUTTOB ON HOVER */
/* .button-border:hover {
    outline: 0px solid transparent;
    border: 2px solid #0198fa;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  } */

/* Testing-----------------------------------------------------------------------------------> */

/* .fixed-sidenav{
    position: fixed;
    z-index: 1;
    top: 10px;
    left: 20px;
    overflow-x: hidden;
    padding-top: 20px;
 } */

/* .fixed-top-nav{
  overflow: hidden;
  background-color: #333;
  position: fixed;
  /* top: 0; */
/* z-index: 1; */
/* }  */

/* ::-webkit-scrollbar {
  display: block !important;
} */

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.fa-info-circle {
  color: var(--font-color);
}

.fa-info-circle:hover {
  color: #000;
}

#submitbutton {
  background-color: #415670;
}

.rs-btn {
  border-radius: 0px !important;
}
