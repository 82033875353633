span[id^="checkerrorField"] {
  position: absolute;
  padding: 4px 8px;
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  z-index: 5;
  color: #f44336;
  font-size: 12px;
  white-space: nowrap;
  display: none;
  bottom: -17px;
}

.text-align-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.nav-item-edit {
  display: flex;
}

.nav-item-edit .nav-item-link {
  flex: 1;
  text-align: center;
  list-style: none;
}

.nav-item-edit .nav-item-link .rs-nav-item:hover {
  background-color: none;
  color: var(--active-font-color);
}

.nav-item-edit .nav-item-link .rs-nav-item {
  color: var(--font-color);
}

.nav-item-edit .nav-item-link .rs-nav-item-active {
  color: var(--active-font-color);
  background: var(--active-bg-color);
  border-radius: 0px;
}

.rs-nav-default .rs-nav-item {
  border-radius: 0px;
}

/* EDIT_DATASET */
.description-left-side {
  /* display: flex; */
  padding: 30px;
  /* justify-content: space-around; */
  margin-top: 25px;
  margin-bottom: 40px;
  background-color: var(--bg-block-color);
  /* border-radius: 40px; */
  /* height: 300px; */
}

.description-table {
  border-spacing: 0 1em;
  border-collapse: separate;
}

.description-table td {
  font-size: 13px;
}

.description-table tr {
  text-align: left;
}

.loader-background-edit .rs-loader-backdrop {
  background-color: var(--bg-block-color);
  border-radius: 40px;
}

.border-bottom {
  border-bottom: 2px solid rgb(196, 189, 189);
}

.font-size-icon {
  font-size: 20px !important;
}

.dropdown-data-more {
  padding: 10px;
}

.dropdown-data-more button {
  color: var(--font-color) !important;
  background: var(--bg-block-color) !important;
}

/* DATASET_EDIT --------> UPLOAD -DATASET*/
.nav-item-data {
  color: var(--font-color);
}

.block-data-list {
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 40px;
  background-color: var(--bg-block-color);
  /* border-radius: 40px; */
}

.uploadXlsx .rs-uploader-file-items {
  display: none;
}

.uploadDataTable {
  border-collapse: collapse;
  width: 100%;
}

.tableHead {
  text-align: center;
  padding: 8px;
  color: #000;
}

.tableVal {
  border: 1px solid var(--font-color);
  text-align: center;
  padding: 8px;
}

.testMe {
  background: red !important;

  width: 300px !important;
  overflow-x: scroll !important;
}
/* Styles for macOS */
@media only screen and (min-device-width: 1024px) and (max-device-width: 2560px) and (-webkit-min-device-pixel-ratio: 2) {
  .block-data-list {
      width: 62%; 
  }
}
 
/* Styles for Windows */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1) {
  .block-data-list {
    width: 62% ; 
}
}