.image-wrap {
  display: inline-block;
  width: 250px;
  height: 250px;
  overflow: hidden;
  margin: 20px;
  cursor: pointer;
}

.image-wrap img {
  width: 100%;
  height: 100%;
  border: solid 1px #ccc;
  /* border-radius: 10px; */
  object-fit: cover;
  object-position: top center;
  /* transition: all 3s ease-in-out; */
}

.image-wrap:hover img {
  object-position: bottom center;
}

.active-template {
  border: 2px solid #000;
  /* border-radius: 10px; */
}

/* .image-wrap label {
    cursor:pointer;
    display: contents;
}

.image-wrap input[type="radio"] {
    display: none;
    opacity:0;
    width: 0 !important;
    height: 0 !important;
  }
  
  .image-wrap input[type="radio"]:checked+label {
    border: solid 2px green;
  } */

/* TEST SEND */

.AddEmailAddress {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-content: center;
}

.emailSubjectLine input {
  width: 300px;
  padding: 5px;
}

.iconname {
  cursor: pointer;
  color: #57b8b8;
  margin-left: 30px;
}

.LeftSidePanel {
  margin-top: 30px;
}

.side-folder-modal {
  overflow-y: auto;
  padding: 10px;
  height: 70vh;
  background: #f0f0f0;
  /* border-radius: 20px; */
  position: sticky;
  /* box-shadow: var(--box-shadow); */
}

.side-folder-modal .folder-structure-sync-assets .e-list-text {
  color: #000;
}

.side-folder-modal .e-treeview .e-list-item div.e-icons {
  color: #000;
}

.asset-img {
  width: 20%;
  cursor: pointer;
  padding: 10px;
}

.asset-img:hover {
  background-color: #e5f3ff;
}
