.email_images_list {
  width: 60px !important;
  height: 60px !important;
  overflow: hidden;
}

.asset-name {
  color: var(--font-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 300px; */
}
/* .DataDetails {
    display: flex;
   
} */

.listview {
  float: left;
  list-style-type: none !important;
  border: 1px solid #d9d9d9;
  width: 100%;
  padding: 5px;
}

/* .block-bg-font-color */

.panel-remove-border {
  /* border: none !important; */
  box-shadow: none;
}

.grid-view-filter-template-image {
  flex-flow: row wrap;
}

.list-view-filter-template-image {
  flex-direction: column;
}

.list-view-filter-template-image .list {
  padding: 10px;
  transition: 0.3s;
  margin-bottom: 20px;
  background-color: var(--bg-block-color);
}

.grid-view-filter-template-image .grid {
  width: 22%;
  float: left;
  margin-top: 0px;
  margin-bottom: 50px;
  margin-right: 2% !important;
  margin-left: 1% !important;
  transition: 0.3s;
  vertical-align: top;
  text-decoration: none;
  /* background-color: var(--bg-block-color); */
  border-radius: 10px;
}

/* IMAGE TRANSITION */

.DataResize {
  overflow: hidden;
  height: 264px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
}
@media only screen and (min-width: 1480px) and (max-width: 1980px) {
  .DataResize {
    height: 303px;
  }
}

.ImageResize {
  overflow: hidden;
  height: 280px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
}

.DataResize img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  object-position: top center;
  transition: all 3s ease-in-out;
}

.DataResize:hover img {
  object-position: bottom center;
}

.ImageResize img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  object-position: top center;
  transition: all 3s ease-in-out;
}

.ImageResize:hover img {
  object-position: bottom center;
}

/* PAGINATION COMPONENT */
.fixed-pagination {
  background-color: var(--bg-block-color);
  position: fixed;
  float: right;
  width: auto;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  padding: 10px;
  border-radius: 10px;
  color: var(--font-color);
}

.pagination-component button {
  color: var(--font-color);
}

.ImageEditOverFlow {
  overflow-x: hidden !important;
}

/* .spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
} */

/*******************\
    Loading Roller
\*******************/
/* @-webkit-keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.lds-roller {
  position: relative;
  display: inline-block;
  height: 64px;
  width: 64px;
}
.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  position: absolute;
  display: block;
  background: #00539f;
  border-radius: 50%;
  content: " ";
  margin: -3px 0 0 -3px;
  height: 6px;
  width: 6px;
}
.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
} */
