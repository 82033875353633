.wrapper {
  display: grid;
  margin: 4em 8em 0em 8em;
  align-content: center;
  grid-template-columns: 50.667% 50.334%;
  padding-bottom: 1%;
}
@media only screen and (min-width: 1480px) and (max-width: 1980px) {
  .wrapper {
    
    margin: 12em 8em 0em 8em;
    
  }
}

.box {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  color: #fff;
  padding: 3px 13px 3px 3px;
  gap: 40px;
}

.right-side-content {
  background-color: rgba(241, 245, 233, 0.7);
}

.loginInput {
  /* color: #000; */
  margin: 0px 10% 50px 10% !important;
  font-size: 16px;
}

.input-field-login {
  border: 1px solid #a3e4f0;
  /* box-shadow: 4px 4px 4px #7d7d7d; */
  padding: 12px;
  /* color: #000; */
  font-size: 16px;
  width: 100% !important;
  border-radius: 0px;
}

.loginInput .rs-form-control-wrapper {
  width: 100% !important;
}

.showHideEye {
  cursor: pointer;
}

.loginInput::placeholder {
  color: #45588c !important;
  opacity: 3;
  /* Firefox */
  /* font-size: 16px !important; */
  font-weight: 400;
}

.loginInput:focus {
  outline: 1px solid #a3e4f0;
}

.login-logo {
  width: 48%;
}

.login-logo-div {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 14%;
}

.login-button-div {
  margin: 0px 0% 0px 10%;
}

.loginButton {
  color: #001a62;
  font-size: 19px;
  background-color: #7ed9ea;
  padding: 10px 3em 10px 3em;
  font-weight: 600;
  /* border-radius: 12px; */
  /* box-shadow: 4px 4px 4px #7d7d7d; */
  margin-right: 10px;
}
.rs-input-group {
  border-radius: 0px;
}
/* .rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left > .rs-icon {
  background-color: #63768d;
} */

.loginButton:hover {
  color: #001a62;
  font-size: 19px;
  background-color: #7ed9ea;
  padding: 10px 3em 10px 3em;
  font-weight: 600;
  /* border-radius: 12px; */
  /* box-shadow: 4px 4px 4px #7d7d7d; */
  margin-right: 10px;
}

.forget-paswword {
  color: #001a62;
  font-size: 14px;
  font-weight: 400;
}

.text {
  padding: 34% 0px 10% 0px;
  text-align: center;
}

.text p {
  padding: 0% 7% 10% 7%;
  color: #001a62;
  font-size: 28px;
  font-weight: 700;
}

.outline {
  color: #a3e4f0;
}

.DSC-text {
  display: flex;
  justify-content: end;
}

.DSC-text p {
  color: #001a62;
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
}

.field-empty-button-disable {
  cursor: context-menu;
}


.contactAdmin{
  cursor: pointer; color: "#001A62";
  margin-top: 20px;
  text-decoration: underline !important;
}
.contactAdminForgotpassword{
  cursor: pointer; color: "#001A62";
  
  text-decoration: underline !important;
}