.nav-menu-profile {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* margin-top: 1em; */
}

.nav-menu-profile .rs-col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.nav-menu-home {
  display: flex;
  justify-content: space-around;
  width: 220px;
}

.modeType :checked + label:before {
  content: "";
  /* background-color: grey; */
  transform: scale(1);
}

.inputS {
  padding: 0px !important;
  color: #000 !important;
  font-size: 16px !important;
  background-color: #f0f0f0 !important;
}

.rs-input-group:focus-within {
  outline: none !important;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.label {
  width: 50px;
  height: 26px;
  background-color: #415670;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.5);
}

.ball {
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
  cursor: pointer;
}

/*  target the elemenent after the label*/
.checkbox:checked + .label .ball {
  transform: translateX(24px);
}

.fa-moon {
  color: pink;
}

.fa-sun {
  color: yellow;
}
.modeType {
  margin-top: 8px;
}
.bell {
  width: 30px;
}

.bellB {
  width: 43px;
  background-color: #f0f0f0;
  border-radius: 45px;
  padding: 7px;
}

.Pic-container {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  overflow: hidden;
}
.profile {
  width: 44px;
}

/*  */

.companyLogo-header {
  width: 210px;
  vertical-align: middle !important;
}

.user-avatar button {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 0px;
  padding-left: 20px;
  text-align: left;
  background-color: transparent;
}

.user-avatar svg {
  display: none !important;
}
.user-avatar {
  color: #000 !important;
  margin-left: 11%;
}
.user-avatar:hover {
  color: #000 !important;
  margin-left: 11%;
}

.rs-sidenav-default,
.rs-sidenav-default .rs-dropdown-toggle {
  background-color: transparent !important;
  width: 230px;

  /* background-color: var(--rs-sidenav-default-bg); */
}
.rs-sidenav-default .rs-dropdown .rs-dropdown-toggle:hover {
  color: #000 !important;
}

.rs-btn:focus-visible {
  outline: 0px !important;
}
.rs-btn:focus-visible:hover {
  outline: 0px !important;
}

.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu {
  width: 260px;
  padding: 0px !important;
  margin: 0px !important;
}

.rs-sidenav-collapse-in .rs-dropdown-item:focus-visible {
  background: none;
  outline: transparent !important;
  outline: transparent !important;
  outline-offset: 0px !important;
}
