.creat-email-form {
  display: flex;
  padding: 20px;
  justify-content: space-around;
  /* margin-top: 15px; */
  margin-bottom: 40px;
  background-color: var(--bg-block-color);
  /* border-radius: 40px; */
}

.input-group-form {
  border: none;
}

.new-email-text {
  /* border: none; */
  border: 1px solid #e5e5ea !important;
  /* background-color: transparent !important; */
  border-radius: 0px !important;
  padding: 7px 7px !important;
  color: var(--font-color) !important;
  display: inline-block !important;
  width: 100%;
}

.new-email-text:focus {
  outline: none !important;
  /* border-color: #3498ff */
}

.input-text-space {
  margin-top: 50px;
  overflow-y: auto;
  width: 100%;
  border-radius: none !important;
}

.input-text-space:focus {
  outline: none !important;
}

.location {
  height: 115px;
  border: 2px solid rgb(131, 128, 128);
}

.email-checkbox {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: var(--font-color) !important;
  font-weight: 600;
}

::placeholder {
  /* color: var(--font-color); */
  opacity: 1;
  /* Firefox */
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}

.creat-camp-form {
  padding: 20px;
  justify-content: space-around;
  margin-top: 15px;
  /* margin-bottom: 40px; */
  background-color: var(--bg-block-color);
  /* border-radius: 40px; */
}

.next-back-button-horizontal {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.next-back-button {
  display: flex;
  justify-content: flex-end;
  color: var(--font-color);
  font-size: 16px;
  font-weight: 600;
  gap: 100px;
}

.nextBack {
  width: 50px;
}

/* EDIT PROPERTIES */ 

.new-email-text-only-underline {
  /* border: none; */
  border: 1px solid #e5e5ea !important;
  /* background-color: transparent !important; */
  border-radius: 0px !important;
  padding: 7px 7px !important;
  display: inline-block !important;
  width: 100%;
}

.new-email-text-only-underline {
  outline: none !important;
  /* border-color: #3498ff */
}

.previousButton {
  font-weight: bold;
  font-size: larger;
  color: #000 !important;
  font-family: arlon-bold WebFont;
  background-color: #ffffff !important;
}

.ButtonNext {
  font-weight: bold;
  font-size: larger;
  color: #000 !important;
  font-family: arlon-bold WebFont;
  background-color: #ffffff !important;
}

.iconsNext {
  width: 50px;
}

/* camDefine */
/* .welcomeMessage
{
    padding: 0px 50px 0px 50px;
    background: #fff;
} */
/* .headernavafterlogin {
    padding-bottom : 20px !important;
} */
.Sort {
  margin-bottom: 15px;
}

/* .navicon{
    background-color: #ffffff;
    margin-left: 25px;
    border-radius: 4px 4px 0px 0px;
    font-size: 15px;
    color: #373737 !important;
   
    text-align: center;
  } */

.rs-nav-item-active {
  border-bottom: none !important;
}

.rs-nav-horizontal {
  border-bottom: none !important;
}

.selectEmail li {
  cursor: pointer !important;
  margin-top: 0px !important;
  margin: 5px;
  padding: 5px;
}

/* .selectEmail li:hover {
  background: #e7e7e7;
 transform: scale(1.1);
 
  } */

.isActive {
  color: white;
  background-color: #666;
}

.dataisActive {
  color: white;
  background-color: #666;
}

.error {
  position: absolute;
  padding: 4px 8px;
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  z-index: 5;
  color: #f44336;
  font-size: 12px;
  white-space: nowrap;
  /* display: none; */
}

/* Start Image template CSS */

input[type="radio"][id^="emailList"] {
  display: none !important;
}

input[type="radio"][id^="dataList"] {
  display: none !important;
}

.TemplateList2 label {
  /* border: 1px solid #fff; */
  padding: 10px;
  display: block;
  position: relative;
  /* margin: 10px; */
  cursor: pointer;
}

.TemplateList2 label:before {
  background-color: white;
  color: white;
  content: " ";
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: 10px;
  left: 140px;
  width: 30px;
  color: lightgreen;
  font-size: 23px;
  height: 30px;
  text-align: center;
  /* line-height: 28px; */
  transition-duration: 0.4s;
  transform: scale(0);
  z-index: 1;
  padding: 10px;
}

.TemplateList2 label img {
  width: 200px;
  transition-duration: 0.2s;
  /* transform-origin: 50% 50%; */
  z-index: 0;
}

:checked + label {
  border-color: #ddd;
}

:checked + label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:checked + label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}

.ScheduleModel .rs-modal-content {
  width: 360px !important;
}

.description-left-side-campaign {
  background-color: var(--bg-block-color);
}
