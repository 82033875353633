.creat-email-form {
  display: flex;
  padding: 20px;
  justify-content: space-around;
  /* margin-top: 15px; */
  margin-bottom: 40px;
  background-color: var(--bg-block-color);
}
.input-group-form {
  border: none;
}
.new-email-text {
  /* border: none; */
  border: 1px solid #e5e5ea !important;
  /* background-color: transparent !important; */
  border-radius: 0px !important;
  padding: 7px 7px !important;
  color: var(--font-color) !important;
  display: inline-block !important;
  width: 100%;
}

.new-email-text:focus {
  outline: none !important;
  /* border-color: #3498ff */
}

.input-text-space {
  margin-top: 50px;
  overflow-y: scroll;
  width: 100%;
  border-radius: none !important;
}

.input-text-space:focus {
  outline: none !important;
}

.location {
  height: 215px !important;
  border: 2px solid rgb(131, 128, 128);
}

.email-checkbox {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: var(--font-color) !important;
  font-weight: 600;
}

input[type="checkbox"] {
  padding: 0;
  width: 30px;
}

::placeholder {
  /* color: var(--font-color); */
  opacity: 1; /* Firefox */
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}

.next-back-button-horizontal {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.next-back-button {
  display: flex;
  justify-content: flex-end;
  color: var(--font-color);
  font-size: 16px;
  font-weight: 600;
  gap: 100px;
}
.nextBack {
  width: 50px;
}

/* EDIT PROPERTIES */

.new-email-text-only-underline {
  border: none;
  border-bottom: 2px solid rgb(164, 164, 164) !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  padding: 7px 7px !important;
  display: inline-block !important;
  width: 100%;
}

.new-email-text-only-underline {
  outline: none !important;
  /* border-color: #3498ff */
}
