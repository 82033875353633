.description-left-side-report {
  padding: 30px 10px 30px 18px;
  background-color: var(--bg-block-color);
  /* border-radius: 40px; */
}

.description-left-side-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  table-layout: fixed;
  vertical-align: top;
}

.description-left-side-table span {
  word-break: break-all;
}

.outer-box-pie {
  border: 1px solid var(--font-color);
  border-radius: 5px;
  padding: 20px;
  margin-left: 2%;
}

.subscriber-count {
  font-size: 24px;
  color: #fff;
  font-weight: normal;
}

.subscriber {
  color: #fff;
}

.CountBox {
  padding: 8px;
  color: #fff;
  /* border-radius: 10px; */
}

.EmailSentbox {
  padding: 8px;
  color: #fff;
  /* margin-top: 8px; */
  /* border-radius: 10px; */
}

.dot-color-pie {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  padding-top: 5px;
}
.EngageReport {
  padding-top: 20px;
}
.CampaignEmailsDetalis {
  font-size: 13px;
  margin-bottom: 2%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px; /* Adjust this value as needed */
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.styled-table {
  border-collapse: collapse;
  width: 100%;
}

.styled-table th,
.styled-table td {
  padding: 10px;
}

.styled-table th {
  background-color: #415670;
  color: #fff;
}

.styled-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.styled-table tr:hover {
  background-color: #415670;
  color: #fff;
}
.download-table-xls-button {
  background-color: #415670; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  padding: 0.5rem;
  /* border-radius: 5px; */
  cursor: pointer;
  font-size: 14px;
  margin-top: 12px;
  /* Add more styles as needed */
}
.download-table-xls-button:hover {
  background-color: #415670; /* Hovered button background color */
}
