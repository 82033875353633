.companyLogo {
  margin: 0% 40px 10% 22px;
}

.logo {
  width: 100%;
}

.sidenav {
  width: 230px !important;
  flex: 0 0 230px !important;
  background-color: var(--bg-block-color);
  height: 100vh;
}

/* .rs-sidenav-nav {
  padding-left: 20px !important;
  margin-bottom: 41px !important;
} */

.rs-sidenav-default {
  /* box-shadow: var(--box-shadow); */
  /* border-radius: 50px; */
  background-color: var(--bg-block-color);
  margin-top: 1rem;
}

.rs-sidenav-default .rs-sidenav-item {
  background-color: var(--bg-block-color);
  /* border-top-left-radius: 50px;
  border-bottom-left-radius: 50px; */
}
.rs-navbar-default {
  background-color: var(--bg-block-color);
}

.rs-sidenav-default.rs-sidenav-collapse-in .rs-sidenav-item {
  font-size: 1rem;
  font-weight: 600;
  /* margin-bottom: 1px !important; */
  /* padding-left: 28px; */
  /* padding-right: 4px; */
  color: var(--font-color);
}

.rs-sidenav-default .rs-sidenav-item:hover {
  background-color: var(--bg-block-color);
}

.rs-sidenav-default.rs-sidenav-collapse-in
  .rs-sidenav-item.rs-sidenav-item-active {
  /* FIXME JIRA- CHAR 43 */
  color: #ffff !important;
  background-color: #415670 !important;
}

/* .rs-sidenav-default.rs-sidenav-collapse-in
  .rs-sidenav-item.rs-sidenav-item-active img{
    content: url('../../assets/icons/Component.png');
} */
a {
  /* color: #000 !important; */
  text-decoration: none !important;
}

.dash-icon {
  width: 17%;
}
.dash-icon-vertical {
  width: 12%;
}

/* ICON_NAVBAR */

.icon-body .rs-sidenav-nav {
  padding: 0px !important;
  margin-bottom: 0px !important;
  width: 80px;
  background-color: #f0f0f0;
}
.icon-body {
  padding: 15px 10px;
  text-align: center;
}
.icon-body .rs-sidenav-nav .rs-sidenav-item {
  padding: 12px 15px;
}

.Iconsidenav {
  /* border-radius: 50px; */
  width: 100px !important;
  flex: 0 0 100px !important;
  margin-top: 0px;
  background-color: #f0f0f0;
  height: 100vh;
}

.Iconsidenav-dash-icon {
  width: 80%;
}
.Iconsidenav-dash-icon-vertical {
  width: 65%;
}

/* #for-hide-sidebar {
  width: 240px !important;
  flex: 0 0 240px !important;
} */

/* #for-hide-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  height: 100vh;
  overflow-y: auto; 
}

#for-create-change-header {
  margin-left: 260px;
  padding-top: 20px; 
} */

.rs-sidenav-nav > .rs-sidenav-item,
.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle {
  padding-left: 1.5rem;
}
