.setting-container {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 4rem;
}

.ip-setting table td {
  border: 1px solid var(--font-color);
  border-collapse: collapse;
  background: var(--bg-block-color);
}
.ip-setting table td {
  padding: 15px;
}

.ip-setting table th {
  border: 1px solid var(--font-color);
  border-collapse: collapse;
  background: var(--bg-block-color);
}
.ip-setting table th {
  padding: 15px;
}


.SettingContainer {
  display: flex;
  justify-content: space-evenly;
}
.dataset {
  background-color: var(--bg-block-color);
}

.center-div-img-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  flex-direction: column;
}

/* .center-div-img-text img {
  width: 33%;
} */
/* picture {
  text-align: center;
} */

.setting_text-type {
  font-size: 15px;
  margin-top: 10px;
  font-weight: bold;
  color: var(--font-color);
  font-family: "Poppins", sans-serif;
}

/* .container-flex {
  display: grid;
  grid-gap: 0px;

  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, auto);
} */

.container-parent {
  display: flex;
  justify-content: center; /* Center the child horizontally */
  align-items: center; /* Center the child vertically */
  /* height: 100vh; Set height to 100% of the viewport */
}

.container-flex-setting {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(
    2,
    minmax(0, 300px)
  ); /* Adjust the minmax values as needed */

  max-width: 800px; /* Set a maximum width for the grid */
  margin: 0 auto; /* Center the grid horizontally */
}

.container-flex-onebox {
  display: grid;
  /* grid-gap: 10px; */
  grid-template-columns: repeat(
    1,
    minmax(0, 300px)
  ); /* Adjust the minmax values as needed */

  max-width: 800px; /* Set a maximum width for the grid */
  margin: 0 auto; /* Center the grid horizontally */
}

/* .create-new-project .new-campaign-detail {
  width: 100%;
} */

.setting_details {
  background-color: var(--bg-block-color);
  /* border-radius: 18px; */
  padding: 18px;
  /* box-shadow: var(--box-shadow); */
}


 